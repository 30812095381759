<template>
  <div class="card-wrap">
    <div class="top-search">
      <div class="card-header-title">商品销量统计</div>
      <div class="flexRow justifyContentSpac">
        <div class="flexRow">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">日期：</div>
            <el-date-picker
                type="daterange"
                v-model="datePicker"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="changePicker"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="flexRow">
          <el-button type="primary" @click="handleExport">导出</el-button>
        </div>
      </div>
    </div>

    <div class="card-echarts">
      <el-table
          border
          :data="tableList"
          v-loading="tableLoading"
          style="width: 100%"
          :header-cell-style="this.utils.getRowClass"
      >
        <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
        <el-table-column align="center" prop="resultDate" label="日期" sortable min-width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" label="订单总数" prop="orderCount" sortable></el-table-column>
        <el-table-column align="center" prop="exchangeCount" sortable label="换货数"></el-table-column>
        <el-table-column align="center" prop="refundCount" sortable label="退货数"></el-table-column>
        <el-table-column align="center" prop="agingCount" label="重复购买数订单数" sortable>
          <template slot-scope="scope">{{scope.row.agingCount ? scope.row.agingCount : 0}}</template>
        </el-table-column>
        <el-table-column align="center" label="换货率" sortable>
          <template slot-scope="scope">{{scope.row.exchangeRate && scope.row.exchangeRate !==0 ? `${scope.row.exchangeRate.toFixed(2)}%` : 0}}</template>
        </el-table-column>
        <el-table-column align="center" label="退货率" sortable>
          <template slot-scope="scope">{{scope.row.refundRate && scope.row.refundRate !==0 ? `${scope.row.refundRate.toFixed(2)}%` : 0}}</template>
        </el-table-column>
        <el-table-column align="center" label="复购率" sortable>
          <template slot-scope="scope">{{scope.row.agingRate && scope.row.agingRate !==0 ? `${scope.row.agingRate.toFixed(2)}%` : 0}}</template>
        </el-table-column>
      </el-table>
      <div class="flexRow justifyContentSpac" style="margin-top:20px">
        <div></div>
        <el-pagination
            @size-change="handleSizeChange"
            background
            layout="total, prev, pager, next,sizes"
            :total="total"
            :page-sizes="[10, 15, 20, 30, 50, 100]"
            :page-size="pageSize"
            @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import base from "../../../../../api/api";
export default {
  name: 'stepFour',
  data() {
    return {
      datePicker: [],
      search: {
        startDate: '',
        endDate: '',
        commodityName: '',
      },
      total: 0,
      page: 1,
      pageSize: 10,
      tableList: [],
      tableLoading: false,
    };
  },
  methods: {
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    getData() {
      let _this = this;

      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }

      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getOrderDataByDay(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 导出
    handleExport() {
      let _this = this;
      _this.$confirm('确定要导出数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        ancelButtonClass:'cancelClass',
        confirmButtonClass:'confirmClass',
        type: 'warning'
      }).then(()=>{

        let url = `${base.url}/common/data/exportOrderFrom?page=1&pageSize=`+_this.total + '&startDate=' + _this.search.startDate + '&endDate=' + _this.search.endDate;
        window.open(url)
      })
    },
    // 时间
    changePicker(e) {
      let _this = this;
      if(e) {
        _this.search.startDate = e[0];
        _this.search.endDate = e[1];
        _this.getData();
      }else {
        _this.search = {
          startDate: '',
          endDate: '',
        }
        _this.datePicker = [];
        _this.getData();
      }
    },
  }
};
</script>
<style scoped lang="less">


.card-header-title {
  margin-bottom: 20px;
}
</style>
