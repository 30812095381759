<template>
  <div class="flexCol alignItems">
    <div class="content edit-page-mb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>表单</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <step-one ref="stepOne"/>
        <step-two ref="stepTwo"/>
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from "./components/step-one";
import StepTwo from "./components/step-two";
export default {
  components: {StepTwo, StepOne},
  data() {
    return {
      tableList: [],
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      noticeInfoAdd: false,
      noticeInfoDel: false,
      noticeInfoUpd: false,
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.$refs.stepOne.getData();
      _this.$refs.stepTwo.getData();
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "noticeInfo:add") {
          _this.noticeInfoAdd = true;
        }
        if (v == "noticeInfo:del") {
          _this.noticeInfoDel = true;
        }
        if (v == "noticeInfo:upd") {
          _this.noticeInfoUpd = true;
        }
      })
    },
  }
};
</script>
<style scoped lang="less">
.content_li {
  background-color: rgba(0,0,0,0);
}
.card-wrap {
  background-color: #fff;
  padding: 10px 30px;
  margin: 30px 0;
  font-size: 16px;
}
</style>
