<template>
  <div class="card-wrap">
    <div class="top-search">
      <div class="card-header-title">销售金额统计</div>
      <div class="flexRow margin-mb">
        <el-button :type="search.type === 2?'primary':''" @click="changeTab(2)">按时间</el-button>
        <el-button :type="search.type === 1?'primary':''" @click="changeTab(1)" style="margin-left:20px;">按商品</el-button>
      </div>
      <div class="flexRow justifyContentSpac">
        <div class="flexRow">
          <div class="flexRow alignItems margin-btn">
            <div class="search-title">日期：</div>
            <el-date-picker
                type="daterange"
                v-model="datePicker"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="changePicker"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="flexRow">
          <el-button type="primary" @click="handleExport">导出</el-button>
        </div>
      </div>
    </div>

    <div class="card-main" v-if="false">
      <div class="card-main-tip" v-for="(item,index) in list" :key="index">
        <i class="item"></i>
        <span>{{item}}</span>
      </div>
    </div>

      <div class="card-echarts">
        <el-table
            border
            :data="tableList"
            v-loading="tableLoading"
            style="width: 100%"
            :header-cell-style="this.utils.getRowClass"
        >
          <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
          <el-table-column align="center" prop="resultDate" label="日期" v-if="search.type === 2"></el-table-column>
          <el-table-column align="center" prop="commodityName" label="商品名称" v-if="search.type === 1"></el-table-column>
          <el-table-column align="center"  label="销售金额">
            <template slot-scope="scope">{{scope.row.saleTotalPrice && scope.row.saleTotalPrice !==0 ? `${(scope.row.saleTotalPrice/100).toFixed(2)}` : 0}}</template>
           </el-table-column>
          <el-table-column align="center" prop="userCount"  label="购买人数" sortable></el-table-column>
          <el-table-column align="center"  label="客单价">
            <template slot-scope="scope">{{scope.row.price && scope.row.price !==0 ? `${(scope.row.price/100).toFixed(2)}` : 0}}</template>
          </el-table-column>
        </el-table>
        <div class="flexRow justifyContentSpac" style="margin-top:20px">
          <div></div>
          <el-pagination
              @size-change="handleSizeChange"
              background
              layout="total, prev, pager, next,sizes"
              :total="total"
              :page-sizes="[10, 15, 20, 30, 50, 100]"
              :page-size="pageSize"
              @current-change="changePage"
          ></el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
import base from "../../../../../api/api";

export default {
  name: 'stepTwo',
  data() {
    return {
      datePicker: [],
      search: {
        startData: '',
        endData: '',
        type: 2, // 时间 商品
      },
      total: 0,
      page: 1,
      pageSize: 10,
      tableList: [],
      tableLoading:false,
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        startData: '',
        endData: '',
        name: ''
      }
      _this.page = 1;
      _this.datePicker = [];
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    // 时间 商品
    changeTab(i) {
      let _this = this;
      _this.search.type = i;
      _this.getData();
    },
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }

      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getSaleDataByFrom(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 导出
    handleExport() {
      let _this = this;
      _this.$confirm('确定要导出数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        ancelButtonClass:'cancelClass',
        confirmButtonClass:'confirmClass',
        type: 'warning'
      }).then(()=>{

        let url = `${base.url}/common/data/exportSalesFrom?page=1&pageSize=`+_this.total + '&type=' + _this.search.type + '&startDate=' + _this.search.startDate + '&endDate=' + _this.search.endDate;
        window.open(url)
      })
    },
    // 时间
    changePicker(e) {
      let _this = this;
      if(e) {
        _this.search.startDate = e[0];
        _this.search.endDate = e[1];
        _this.getData();
      }else {
        _this.search.startDate = '';
        _this.search.endDate = '';
        _this.datePicker = [];
        _this.getData();
      }
    },
  }
};
</script>
<style scoped lang="less">
.card-wrap {
  background-color: #fff;
  padding: 10px 30px;
  margin: 30px 0;
  font-size: 16px;
}

.card-header-title {
  margin-bottom: 20px;
}
.card-main {
  display: flex;
}
.card-main> .card-main-tip {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.card-main> .card-main-tip > .item {
  position: relative;
  width: 50px;
}

.card-main .card-main-tip > .item:before {
  content: ''; /*CSS伪类用法*/
  position: absolute; /*定位背景横线的位置*/
  top: 0;
  //background: #409EFF; /*宽和高做出来的背景横线*/
  width: 30px;
  height: 2px;
}
.card-main .card-main-tip:nth-child(1) > .item:before {
  background: #409EFF; /*宽和高做出来的背景横线*/
}
.card-main .card-main-tip:nth-child(2) > .item:before {
  background: #67C23A; /*宽和高做出来的背景横线*/
}
.card-main .card-main-tip:nth-child(3) > .item:before {
  background: #494949; /*宽和高做出来的背景横线*/
}
.margin-mb {
  margin-bottom: 20px;
}
</style>
